<template>

  <div>
    <!-- Table Container Card -->
    <b-card
      title="Vehicle Checks"
      class="mb-0"
    >
      <b-table
        ref="refVehicleCheckTable"
        class="position-relative"
        :items="fetchVehicleChecks"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >

        <!-- Column: Outcome -->
        <template #cell(outcome)="data">
          <div class="text-nowrap text-center">
            <feather-icon
              :icon="resolveCheckOutcomeIcon(data.item.outcome)"
              size="18"
              :class="`text-${resolveCheckOutcomeVariant(data.item.outcome)}` "
            />
          </div>
        </template>

        <!-- Date -->
        <template #cell(createdAt)="data">
          <div class="text-nowrap">
            {{ data.item.updatedAt !== undefined ? toHumanDate(data.item.updatedAt) : 'TBD' }}
          </div>
        </template>

        <!-- Mileage -->
        <template #cell(mileage)="data">
          <div class="text-nowrap">
            {{ data.item.mileage + ' mi' }}
          </div>
        </template>

        <!-- Driver -->
        <template #cell(driver)="data">
          <div class="text-nowrap">
            {{ data.item.driver.fullName }}
          </div>
        </template>

        <!-- Note -->
        <template #cell(note)="data">
          <b-button
            title="View Note"
            variant="warning"
            :disabled="!data.item.note"
            size="sm"
            @click="showNote(data.item.note)"
          >
            View Note
          </b-button>
        </template>

        <!-- Actions -->
        <template #cell(actions)="data">
          <div class="text-nowrap">
            <b-button
              size="sm"
              variant="outline-primary"
              :to="{name: 'vehicles-check-view', params: { id: data.item.id}}"
            >
              Details
            </b-button>
          </div>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalVehicleChecks"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
    <b-modal
      id="modal-note"
      ref="modal-note"
      modal-class="modal-info"
      hide-footer
      centered
      title="Vehicle Check Note"
    >
      <b-card-text>
        {{ currentNote }}
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton, BCard, BCardText, BCol, BPagination, BRow, BTable, VBModal,
} from 'bootstrap-vue'
import useVehicleCheckList from '@/views/vehicle/useVehicleCheckList'
import toHumanDate from '@/libs/utils/general/dates'
import { ref } from '@vue/composition-api'

export default {
  components: {
    BCard,
    BCardText,
    BRow,
    BCol,
    BTable,
    BPagination,
    BButton,
  },
  directives: {
    'b-modal': VBModal,
  },
  props: {
    vehicleId: {
      type: String,
      required: true,
    },
  },
  methods: {
    showNote(note) {
      this.$refs['modal-note'].show()
      this.currentNote = note
    },
  },
  setup(props) {
    const currentNote = ref(null)
    const {
      fetchVehicleChecks,
      tableColumns,
      perPage,
      currentPage,
      totalVehicleChecks,
      dataMeta,
      perPageOptions,
      sortBy,
      isSortDirDesc,
      refVehicleCheckTable,
      refetchData,

      // UI
      resolveCheckOutcomeVariant,
      resolveCheckOutcomeIcon,

    } = useVehicleCheckList({ vehicleId: props.vehicleId })

    return {
      fetchVehicleChecks,
      tableColumns,
      perPage,
      currentPage,
      totalVehicleChecks,
      dataMeta,
      perPageOptions,
      sortBy,
      isSortDirDesc,
      refVehicleCheckTable,
      refetchData,

      // UI
      resolveCheckOutcomeVariant,
      resolveCheckOutcomeIcon,
      toHumanDate,

      currentNote,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>
